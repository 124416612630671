import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const AboutContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allAboutJson {
          edges {
            node {
              id
              pageTitle
              heroBannerSection {
                bannerImage
                heading
                content
                actionButton {
                  buttonLabel
                  buttonPath
                }
              }
              horizontalCardSection {
                hideSection
                heading
                content
                horizontalCard {
                  heading
                  content
                  cardImage
                }
              }
              threeColumnCardSection {
                hideSection
                heading
                content
                bannerImage
                threeColumnCard {
                  heading
                  content
                }
              }
            }
          }
        }
      }
    `}
    render={({ allAboutJson: { edges: aboutData } }) =>
      children(
        aboutData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

AboutContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default AboutContainer;
