import React from "react";
import Box from "../../base/Box";
import SEO from "../SEO/seo";
import Section from "../Section";
import AspectRatio from "../AspectRatio";
import BgImage from "../BgImage";
import AboutContainer from "../../containers/AboutContainer";
import MarkdownToHtml from "../MarkdownToHtml";
import PageHeroBanner from "../PageHeroBanner";

const AboutPage = () => {
  let prevIndex = 0;
  const setSection = (sectionDetails) => {
    const { horizontalCardSection, heroBannerSection, threeColumnCardSection } =
      sectionDetails;
    const { horizontalCard } = horizontalCardSection;
    const { threeColumnCard } = threeColumnCardSection;
    return (
      <>
        {heroBannerSection && (
          <PageHeroBanner
            actionButton={heroBannerSection.actionButton}
            bannerImage={heroBannerSection.bannerImage}
            content={{
              heading: heroBannerSection.heading,
              text: heroBannerSection.content,
            }}
          />
        )}
        {/* //////////////////////////// Horizontal Card Section//////////////////////////// */}

        {horizontalCardSection && !horizontalCardSection.hideSection && (
          <Section>
            {horizontalCardSection.heading && (
              <MarkdownToHtml
                content={horizontalCardSection.content}
                heading={horizontalCardSection.heading}
                sx={{
                  mb: [4, null, null, 5],
                  textAlign: ["center", null, null, null, "left"],
                }}
              />
            )}
            {horizontalCard &&
              horizontalCard.map((cardItem) => {
                const currentIndex =
                  prevIndex > 0 && prevIndex % 2 !== 0 ? 2 : 1;
                const isOdd = currentIndex % 2 !== 0;
                prevIndex = currentIndex;
                return (
                  <Box
                    sx={{
                      "&:not(:last-child)": {
                        mb: [4, null, null, 5],
                      },
                      display: "flex",
                      flexDirection: isOdd ? "" : "row-reverse",
                      flexWrap: "wrap",
                      // Add below margin to align "design element" with other divs
                      // mx: "10px",
                    }}
                  >
                    {/* ///////////////////////////////////// Image Section ///////////////////////////////////// */}
                    <Box
                      sx={{
                        ":before": {
                          bg: "blueLight",
                          content: "''",
                          height: "50%",
                          position: "absolute",
                          [isOdd ? "left" : "right"]: [
                            "50%",
                            null,
                            null,
                            null,
                            "50%",
                          ],
                          [isOdd ? "ml" : "mr"]: [
                            "-50vw",
                            null,
                            null,
                            null,
                            "-50vw",
                          ],
                          top: "25%",
                          width: "50vw",
                        },
                        position: "relative",
                        textAlign: [
                          "center",
                          null,
                          null,
                          null,
                          isOdd ? "left" : "right",
                        ],
                        width: ["100%", null, null, null, "50%"],
                      }}
                    >
                      <AspectRatio
                        ratio={[1, 1]}
                        sx={{
                          bg: "primarySubtle",
                          width: "80%",
                        }}
                      >
                        <Box
                          sx={{
                            // position: "relative",
                            ":after": {
                              bg: "yellowDark",
                              bottom: ["-5px", null, null, "-10px"],
                              content: "''",
                              height: "15%",
                              position: "absolute",
                              [isOdd ? "left" : "right"]: [
                                "-5px",
                                null,
                                null,
                                "-10px",
                              ],
                              width: ["5px", null, null, "10px"],
                            },
                            ":before": {
                              bg: "yellowDark",
                              bottom: ["-5px", null, null, "-10px"],
                              content: "''",
                              height: ["5px", null, null, "10px"],
                              position: "absolute",
                              [isOdd ? "left" : "right"]: [
                                "-5px",
                                null,
                                null,
                                "-10px",
                              ],
                              width: "40%",
                            },
                            backgroundPosition: "center",
                            my: "auto",
                          }}
                        >
                          <BgImage
                            name={cardItem.cardImage}
                            page="about"
                            sx={{
                              backgroundPosition: "inherit",
                              backgroundSize: "cover",
                            }}
                          />
                        </Box>
                      </AspectRatio>
                    </Box>

                    {/* ///////////////////////////////////// Text Section ///////////////////////////////////// */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: [null, null, null, null, "center"],
                        pb: 4,
                        pt: [4, null, null, 6],
                        textAlign: ["center", null, null, null, "left"],
                        width: ["100%", null, null, null, "50%"],
                        [isOdd ? "ml" : "mr"]: [null, null, null, null, -5],
                      }}
                    >
                      <MarkdownToHtml
                        content={cardItem.content}
                        heading={cardItem.heading}
                        sx={{ p: { fontSize: [1, null, null, null, 2] } }}
                      />
                    </Box>
                  </Box>
                );
              })}
          </Section>
        )}

        {threeColumnCardSection && !threeColumnCardSection.hideSection && (
          <Section
            bgImage={threeColumnCardSection.bannerImage}
            overlay
            overlaySx={{
              bg: "grays.5",
              opacity: "67%",
            }}
            sx={{
              bg: "grays.5",
              textAlign: ["center", null, null, "left"],
            }}
          >
            <MarkdownToHtml
              content={threeColumnCardSection.content}
              contentSx={{
                p: { color: "white" },
              }}
              heading={threeColumnCardSection.heading}
              sx={{ mb: 4 }}
            />
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mx: -4,
              }}
            >
              {threeColumnCard.map((cardItem) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    px: 4,
                    py: [3, null, null, 4],
                    width: ["100%", null, "calc(100% / 2)", "calc(100% / 3)"],
                  }}
                >
                  <Box
                    sx={{
                      bg: "white",
                      boxShadow: 3,
                      display: "flex",
                      flex: "auto",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      position: "relative",
                      px: [3, null, null, 4],
                      py: 4,
                    }}
                  >
                    <MarkdownToHtml
                      content={cardItem.content}
                      contentSx={{
                        mt: 4,
                      }}
                      heading={cardItem.heading}
                      headingSx={{
                        ":before": {
                          bg: "yellowDark",
                          bottom: -3,
                          content: "''",
                          height: "1.2px",
                          left: "0px",
                          position: "absolute",
                          width: "100%",
                        },
                        h3: {
                          color: "text.para",
                          fontSize: 2,
                          fontWeight: "bold",
                        },
                        position: "relative",
                      }}
                      sx={{
                        textAlign: ["center", null, null, "left"],
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Section>
        )}
      </>
    );
  };
  return (
    <AboutContainer>
      {(aboutData) => {
        const pageId = "about";
        const aboutInfo = aboutData.find((data) => data.id === pageId);

        return (
          <>
            <SEO pathname="about" templateTitle={aboutInfo.pageTitle} />
            {setSection(aboutInfo)}
          </>
        );
      }}
    </AboutContainer>
  );
};

AboutPage.propTypes = {};

export default AboutPage;
